import * as API from "./axios";
import {API_BASE_URL as BASE_URL} from '../const';


export async function searchSVTTrackingReports(channel,start_date,end_date) {
    try {
        const result = await API.init().get(BASE_URL + `/svt-tracking-reports/search?channel=${channel}&start_date=${start_date}&end_date=${end_date}`);
        if (result && result.data) {
            return result.data;
        }
    } catch (err) {
        return buildErrorResponse(err);
    }
}

function buildErrorResponse(err) {
    let res = err?.response;
    return {
        'message': res?.data?.message,
        'status': 'ERROR',
        'statusCode': res?.status
    }
}

const SVTTrackingReportsService = {
    searchSVTTrackingReports
};

export default SVTTrackingReportsService;
