import { Spinner, Table, Alert } from 'reactstrap';
import './SVTTrackingReport.css';
import React, { useState } from 'react';
import moment from 'moment/moment';
import SVTTrackingReportsService from '../../../services/svt-tracking-reports.service';
import { generateCsvContent } from '../../utils';
import { ERROR_STATUS } from '../../../const';
var FileSaver = require('file-saver');
const reportTypes = ['TMCA', 'HMCA', 'GMA', 'MMAL'];
const DATE_FORMAT = "YYYY-MM-DD";
const FILE_DATE_FORMAT = "YYYYMMDD";

const ERROR_MESSAGE = {
  EMPTY: "No reports are ready for the selected period.",
  ERROR: "Oops there was an error ! Please try again."
}

const initialState = {
  reports: [],
  type: '',
  startDate: '',
  endDate: '',
  fileName: '',
  loading: false,
  error: ''
};

const getFileName = (type, startDate, endDate) => {

  const startDateStr = moment(startDate).format(FILE_DATE_FORMAT);
  const endDateStr = moment(endDate).format(FILE_DATE_FORMAT);
  return `${startDateStr}-${endDateStr}-${type.toLowerCase()}_svttrackingreport.csv`;
}

function SVTTrackingReport() {
  const [reportState, setReportState] = useState(initialState);
  const [searchCriteriaState, setSearchCriteriaState] = useState({
    selectedType: 'TMCA',
    selectedStartDate: moment().format(DATE_FORMAT),
    selectedEndDate: moment().format(DATE_FORMAT),
  });
  const [loading, setLoading] = useState(false);

  const onClickRunReport = () => {
    setLoading(true);
    SVTTrackingReportsService.searchSVTTrackingReports(searchCriteriaState.selectedType, searchCriteriaState.selectedStartDate, searchCriteriaState.selectedEndDate)
    .then(res => {
      if (res.status !== ERROR_STATUS) {
        setReportState({...reportState, 
          fileName: getFileName(searchCriteriaState.selectedType, searchCriteriaState.selectedStartDate, searchCriteriaState.selectedEndDate),
          reports: res ?? [],
          type: searchCriteriaState.selectedType,
          startDate: searchCriteriaState.selectedStartDate,
          endDate: searchCriteriaState.selectedEndDate,
          error: (res ?? []).length === 0 ? ERROR_MESSAGE.EMPTY : ''
        });
      } else {
        setReportState({...reportState,
          reports: [],
          error: ERROR_MESSAGE.ERROR
        });
      }
      
    }).catch(_ => {
      setReportState({...reportState,
        reports: [],
        error: ERROR_MESSAGE.ERROR
      });
    }).finally(() => setLoading(false));
  };  


  const downloadReport = () => {
    
    const headers = ["Channel","Case report","External ID","MSISDN","VIN","Model Details","Comments","Start Location","End Location","Initiated By","Deactivated By","Recovered","Triangulation Use0d","Recovered Date","Recovered Area","Owner","Published Timestamp","Real or Test"];
    const rows = reportState.reports.map(row => [`${row.channel}`,`${row.service_id}`,`${row.external_service_ids.join(",")}`,`${row.msisdn}`,`${row.vin}`,`${row.model}`,`${row.comments}`,`${row.start_location}`,`${row.end_location}`,`${row.initiated_by}`,`${row.deactivated_by}`,`${row.recovery}`,`${row.triangulation_used}`,`${row.recovery_date}`,`${row.recovery_area}`,`${row.owner}`,`${row.published_timestamp}`,`${!row.is_test ? "Real": "Test"}`]);
    const blob = new Blob([generateCsvContent(headers, rows)], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, `${reportState.fileName}`);
  }

  return (
    <div className="provision-device-container">
      <form>
        <h2 className="weekly-report-header">SVT Tracking Report</h2>
        <br />
        <div>
          <label className="weekly-report-tbl-label">Type: </label>
          <select className="weekly-report-tbl-select" onChange={e => setSearchCriteriaState({ ...searchCriteriaState, selectedType: e.target.value })}>
            {
              reportTypes?.map(t => <option key={t}>{t}</option>)
            }
          </select>
          <label className="weekly-report-tbl-label">Start Date: </label>
          <input className="weekly-report-tbl-select" type="date" value={searchCriteriaState.selectedStartDate}
            onChange={e => setSearchCriteriaState({ ...searchCriteriaState, selectedStartDate: moment(e.target.value).format(DATE_FORMAT) })}
            max={searchCriteriaState.selectedEndDate ?? undefined} />
          <label className="weekly-report-tbl-label">End Date: </label>
          <input className="weekly-report-tbl-select" type="date" value={searchCriteriaState.selectedEndDate}
            min={searchCriteriaState.selectedStartDate ?? undefined}
            onChange={e => setSearchCriteriaState({ ...searchCriteriaState, selectedEndDate: moment(e.target.value).format(DATE_FORMAT) })} />

          <button className="btn btn-primary weekly-report-tbl-button" type='button' onClick={onClickRunReport}>Search</button>
        </div>
      </form>
      {
        loading && <Spinner animation="border" role="status" className="weekly-report-spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {
        !loading && reportState.reports && reportState.reports.length > 0 &&
        <div>
          <br />
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Filename</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td>{reportState.type}</td>
                  <td>{reportState.startDate}</td>
                  <td>{reportState.endDate}</td>
                  <td>{reportState.fileName}</td>
                  <td><input className="btn btn-primary weekly-report-row" onClick={() => downloadReport()} type={'button'} value='Download' /></td>
              </tr>
            </tbody>
          </Table>
        </div>
      }
      {
        !loading && (reportState.error) &&
        <div>
          <br />
          <Alert color={reportState.reports.length === 0 ? "light" : "danger"} fade>
            {reportState.error}
          </Alert>
        </div>
      }
    </div>
  );
}

export default SVTTrackingReport;
